.outerDiv {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  color: var(--gray);
  background-color: var(--white);
}

.tabs {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--gray86);
  overflow-x: scroll;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab {
  padding: 1rem;
  text-decoration: none;
  font-weight: 700;
  color: var(--light-gray);
}

.activeTab {
  color: var(--gray);
  border-bottom: 1px solid var(--gray);
}

.icon {
  width: 15px;
  height: 15px;
}

.content {
  padding: 1rem;

  width: 100%;
  height: 100%;
}
